<template>
  <div ref="rootDiv" v-leave-and-click="onLeaveAndClick">
    <el-form :disabled="isShow" :model="form" size="small" @submit.native.prevent>
      <div v-loading="loadFlag || thisLoadFlag" class="sortable-column">
        <!--
            checkMethod: $route.path.indexOf('list') !== -1 ? ({ row }) => Number(row.stff_id) === Number($cookies.get('userInfo').stff_id) : selectable
        -->
        <!--keep-source-->
        <ux-grid
          v-if="!tableStatus"
          id="dynamicForm"
          ref="multiTable"
          :cell-style="cellStyle"
          :checkbox-config="{
            checkMethod: selectable
          }"
          :data="tableData"
          :data-changes-scroll-top="false"
          :edit-config="{ trigger: 'click', mode: 'row', autoClear: false, showIcon: false, activeMethod: activeMethod }"
          :expand-config="{ accordion: true }"
          :header-cell-style="headerCellStyle"
          :height="tableMaxHeight"
          :highlight-current-row="true"
          :row-class-name="rowClassName"
          :row-style="extendRowStyle"
          :rules="rules"
          :show-header="showHeader"
          :show-summary="showSummary"
          :size="'medium'"
          :stripe="stripe"
          :summary-method="obj => (needAllColumnSummary ? getAllSummaries(obj) : getSummaries(obj))"
          :valid-config="{ autoPos: false }"
          :sort-config="sortStatus ? {} : { sortMethod: () => {} }"
          column-key
          v-bind="$attrs"
          widthResize
          @select="tableSelect"
          v-on="$listeners"
          @table-body-scroll="scroll"
          @edit-closed="editClosed"
          @edit-actived="editActived"
          @selection-change="tableSelectionChange"
          @select-all="tableSelectAll"
          @row-dblclick="dbClickJp"
          @row-click="rowClick"
          @sort-change="sortChange"
          @cell-mouse-enter="cellMouseEnter"
        >
          <ux-table-column v-if="needExpand" :fixed="expandFixed" align="center" type="expand" width="50">
            <template v-slot:content="{ row, rowIndex }">
              <slot name="expand" v-bind="{ row: row, $index: rowIndex }" />
            </template>
          </ux-table-column>
          <ux-table-column v-if="needCheckBox" :fixed="checkBoxFixed" align="center" type="checkbox" width="50" />
          <ux-table-column v-if="needIndex" :fixed="indexFixed" :title="indexTitle ? indexTitle : '序号'" align="center" type="index" width="60">
            <template v-slot:header>
              <div v-if="needChangeColumnProperties" style="position: absolute; left: 1px">
                <el-button v-if="operationBtnShow === false" size="mini" type="primary" @click="operationBtnShow = true">操作</el-button>
                <el-button v-else size="mini" type="primary" @click="saveColumnsFixedStatus">保存</el-button>
              </div>
              <div :class="isSecondaryHeader ? 'flexHV mgt' : 'flexHV'" :style="needChangeColumnPropertiesStyle">
                <div v-if="!needChangeColumnProperties" class="flexHV" style="height: 34px">序号</div>
              </div>
            </template>
          </ux-table-column>
          <template v-for="(item, index) in tableColumn">
            <!--单表头 :field="`tableData.${index}.${item.prop}`"-->
            <ux-table-column
              v-if="!item.secondaryHeader"
              :key="index"
              :align="item.align || 'center'"
              :edit-render="{ autofocus: '.el-input__inner' }"
              :field="item.prop"
              :fixed="item.fixed"
              :remote-sort="!!item.remoteSort"
              :resizable="true"
              :sortable="item.sortable"
              :title="item.label"
              :visible="item.visible"
              :min-width="columnMinWidth(item)"
            >
              <!--表头显示-->
              <template v-slot:header>
                <div v-if="operationBtnShow === true" style="position: absolute; left: 0; width: 100%; display: flex; top: 0" @click="e => e.stopPropagation()">
                  <el-link
                    v-if="!columnsFixedStatus[item.prop]"
                    class="vg_cursor"
                    size="mini"
                    style="margin-top: 5px"
                    type="danger"
                    @click="columnsFixedStatus[item.prop] = true"
                    >冻结
                  </el-link>
                  <el-link v-else class="vg_cursor" size="mini" style="margin-top: 5px" type="success" @click="columnsFixedStatus[item.prop] = false">
                    解冻
                  </el-link>
                  <el-link
                    class="vg_cursor"
                    size="mini"
                    style="margin-left: auto; margin-right: 10px; margin-top: 5px"
                    type="warning"
                    v-if="isCopyColumn"
                    @click="copyColumn(item)"
                  >
                    复制列
                  </el-link>
                </div>
                <div v-if="$scopedSlots[`header-${item.prop}`]" style="width: 100%">
                  <slot :name="`header-${item.prop}`" v-bind="searchForm" />
                </div>
                <div v-else :class="isSecondaryHeader ? 'flexHV mgt' : 'flexHV'" :style="needChangeColumnPropertiesStyle" @click="e => e.stopPropagation()">
                  <div>
                    <div :class="`${item.subItem ? (item.subItem.required ? 'vg_deep_red flexHV' : 'flexHV') : 'flexHV'}`" style="height: 34px">
                      {{ item.label }}
                    </div>
                    <div v-if="needSearch">
                      <div v-if="item.itemType === 'slot'" style="width: 100%">
                        <slot :name="`header-search-${item.prop}`" v-bind="{ searchForm }" />
                      </div>
                      <el-date-picker
                        v-if="item.itemType === 'date'"
                        v-model="searchForm[item.otherProp ? item.otherProp : item.prop]"
                        :disabled="item.input === false"
                        :placeholder="item.input === false ? '' : '请选择'"
                        :value-format="item.valueFormat"
                        clearable
                        show-word-limit
                        size="mini"
                        type="date"
                        @change="getData"
                      />
                      <el-input
                        v-if="item.itemType === 'input'"
                        v-model="searchForm[item.otherProp ? item.otherProp : item.prop]"
                        :disabled="item.input === false"
                        :placeholder="item.input === false ? '' : '请输入'"
                        clearable
                        size="mini"
                        @clear="getData"
                        @input="val => (item.searchInput ? item.searchInput(val, searchForm, item.prop) : '')"
                        @keyup.enter.native="getData(index)"
                      />
                      <el-select
                        v-if="item.itemType === 'select'"
                        v-model="searchForm[item.otherProp ? item.otherProp : item.prop]"
                        :allow-create="item.allowCreate"
                        :collapse-tags="item.collapseTags"
                        :disabled="item.input === false"
                        :filter-method="item.filterMethod"
                        :filterable="item.filterable"
                        :loading="item.loading"
                        :loading-text="item.loadingText"
                        :multiple="item.multiple"
                        :multiple-limit="item.multipleLimit"
                        :name="item.name"
                        :no-data-text="item.noDataText"
                        :no-match-text="item.noMatchText"
                        :placeholder="item.input === false ? '' : '请选择'"
                        :popper-class="item.popperClass"
                        :remote="item.remote"
                        :remote-method="item.remoteMethod"
                        clearable
                        size="mini"
                        @clear="getData(index)"
                        @visible-change="val => headerSearchVisibleChange(val, item, index)"
                        @remove-tag="val => headerRemoveTagChange(val, searchForm[item.otherProp ? item.otherProp : item.prop])"
                      >
                        <el-option
                          v-for="(item, index) in item.options"
                          :key="`${tableRowKey}select${item.value}${index}`"
                          :disabled="item.disabled"
                          :label="item.label"
                          :style="selectOptionStyle"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </template>
              <!--编辑显示-->
              <template v-slot:edit="{ row, rowIndex }">
                <div
                  v-if="item.currColumnVisible ? item.currColumnVisible(row) : true"
                  style="width: 100%; height: 100%; display: flex"
                  @click="e => e.stopPropagation()"
                >
                  <div style="width: 100%" v-if="$scopedSlots[item.prop]">
                    <slot :name="item.prop" v-bind="{ row: row, $index: rowIndex }" />
                  </div>
                  <span v-else-if="$scopedSlots[`special-${item.prop}`]">
                    <slot :name="`special-${item.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                  </span>
                  <div style="width: 100%" v-else-if="item.subItem">
                    <div style="width: 100%" v-if="item.subItem.type === 'input'">
                      <el-input
                        v-if="item.subItem.skipCalc === 'function' ? !item.subItem.skipCalc(row) : computedData && computedData[item.prop]"
                        :value="typeof computedData[item.prop] === 'function' ? computedData[item.prop](rowIndex) : computedData[item.prop]"
                        disabled
                        placeholder="自动"
                        size="small"
                      />
                      <el-input
                        style="width: 100%"
                        v-else
                        v-model="row[item.prop]"
                        :autosize="item.subItem.autosize"
                        :clearable="item.subItem.clearable"
                        :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                        :max="typeof item.subItem.max === 'function' ? item.subItem.max(row) : item.subItem.max"
                        :maxlength="item.subItem.maxLength"
                        :min="typeof item.subItem.min === 'function' ? item.subItem.min(row) : item.subItem.min"
                        :placeholder="item.subItem.placeholder ? item.subItem.placeholder : '请输入'"
                        :type="item.subItem.inputType"
                        clearable
                        show-word-limit
                        size="small"
                        @blur="event => (item.subItem.blur ? item.subItem.blur(event, row) : () => {})"
                        @change="event => (item.subItem.change ? item.subItem.change(event, row) : () => {})"
                        @clear="row[item.prop] = null"
                        @input="event => (item.subItem.input ? item.subItem.input(event, row) : () => {})"
                      >
                        <template v-if="$scopedSlots[`subItem-${item.prop}`]" slot="append">
                          <slot :name="`subItem-${item.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                        </template>
                      </el-input>
                    </div>
                    <div v-if="item.subItem.type === 'image'" class="flexHV" style="height: 80px">
                      <el-image
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(row) : item.subItem.thumbnail"
                        class="flexHV"
                        lazy
                        style="height: 80px; width: 80px"
                      >
                        <span slot="error" v-html="'暂无'"></span>
                      </el-image>
                    </div>
                    <div v-if="item.subItem.type === 'uploadImage'" style="display: flex">
                      <el-image
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(row) : item.subItem.thumbnail"
                        class="flexHV"
                        lazy
                        style="height: 80px; width: 80px"
                      >
                        <span slot="error" v-html="'暂无'"></span>
                      </el-image>
                      <el-upload
                        :ref="`uploadMultiple${rowIndex}`"
                        :action="uploadUrl"
                        :before-upload="beforeAvatarUpload"
                        :data="dataBody"
                        :on-success="res => handleAvatarSuccess(res, row, item.subItem.imageField, item.subItem.thumbnailField)"
                        :show-file-list="false"
                      >
                        <el-link :disabled="item.subItem.specialShow ? !item.subItem.specialShow : isShow" class="vg_cursor" type="primary">点击上传</el-link>
                      </el-upload>
                      <div id="preview" class="flexHV">
                        <el-link
                          slot="trigger"
                          :disabled="item.subItem.specialShow ? !item.subItem.specialShow : isShow"
                          class="vg_cursor"
                          type="primary"
                          @click="handlePaste(false, rowIndex)"
                          >粘贴
                        </el-link>
                      </div>
                    </div>
                    <el-date-picker
                      v-if="item.subItem.type === 'date'"
                      v-model="row[item.prop]"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      clearable
                      placeholder="请选择"
                      show-word-limit
                      size="small"
                      type="date"
                      value-format="timestamp"
                      @change="val => (typeof item.subItem.change === 'function' ? item.subItem.change(val, row) : '')"
                    >
                    </el-date-picker>
                    <el-select
                      v-if="item.subItem.type === 'select'"
                      v-model="row[item.prop]"
                      :allow-create="item.subItem.allowCreate"
                      :clearable="item.subItem.clearable"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      :filter-method="item.subItem.filterMethod"
                      :filterable="item.subItem.filterable ? item.subItem.filterable : true"
                      :loading="item.subItem.loading"
                      :loading-text="item.subItem.loadingText"
                      :multiple="item.subItem.multiple"
                      :multiple-limit="item.subItem.multipleLimit"
                      :name="item.subItem.name"
                      :no-data-text="item.subItem.noDataText"
                      :no-match-text="item.subItem.noMatchText"
                      :placeholder="item.subItem.placeholder"
                      :popper-class="item.subItem.popperClass"
                      :remote="item.subItem.remote"
                      :remote-method="item.subItem.remoteMethod"
                      automatic-dropdown
                      clearable
                      size="small"
                      @change="
                        event =>
                          item.subItem.change
                            ? item.subItem.change(event, row, (item.subItem.dynamicOptions ? item.subItem.dynamicOptions(row) : false) || item.subItem.options)
                            : () => {}
                      "
                      @visible-change="typeof item.subItem.visibleChange === 'function' ? item.subItem.visibleChange($event) : () => {}"
                      @remove-tag="typeof item.subItem.removeTag === 'function' ? item.subItem.removeTag($event) : () => {}"
                    >
                      <el-option
                        v-for="(subOption, index) in item.subItem.dynamicOptions ? item.subItem.dynamicOptions(row) : item.subItem.options"
                        :key="`subSelect${subOption.value}${index}`"
                        :disabled="subOption.disabled"
                        :label="subOption.label"
                        :style="selectOptionStyle"
                        :value="subOption.value"
                      />
                    </el-select>
                    <el-select
                      v-if="item.subItem.type === 'newSelect'"
                      v-model="row[item.prop]"
                      :allow-create="item.subItem.allowCreate"
                      :clearable="item.subItem.clearable"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      :filter-method="item.subItem.filterMethod"
                      :filterable="item.subItem.filterable ? item.subItem.filterable : true"
                      :loading="item.subItem.loading"
                      :loading-text="item.subItem.loadingText"
                      :multiple="item.subItem.multiple"
                      :multiple-limit="item.subItem.multipleLimit"
                      :name="item.subItem.name"
                      :no-data-text="item.subItem.noDataText"
                      :no-match-text="item.subItem.noMatchText"
                      :placeholder="item.subItem.placeholder"
                      :popper-class="item.subItem.popperClass"
                      :remote="item.subItem.remote"
                      :remote-method="item.subItem.remoteMethod"
                      automatic-dropdown
                      clearable
                      size="small"
                      @change="event => (item.subItem.change ? item.subItem.change(event, row, item.subItem.options) : () => {})"
                      @visible-change="typeof item.subItem.visibleChange === 'function' ? item.subItem.visibleChange($event) : () => {}"
                      @remove-tag="typeof item.subItem.removeTag === 'function' ? item.subItem.removeTag($event) : () => {}"
                    >
                      <el-option
                        v-for="(subOption, index) in Object.values(item.subItem.options)"
                        :key="`subSelect${subOption.value}${index}`"
                        :disabled="subOption.disabled"
                        :label="subOption.label"
                        :style="selectOptionStyle"
                        :value="subOption.value"
                      />
                    </el-select>
                    <el-input
                      v-else-if="item.subItem.type === 'popoverInput'"
                      v-model="row[item.prop]"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      :maxlength="item.subItem.maxLength"
                      show-word-limit
                      size="small"
                    >
                      <template slot="append">
                        <el-popover placement="bottom" trigger="click" width="600">
                          <el-input
                            v-model="row[item.prop]"
                            :autosize="{ minRows: 5, maxRows: 10 }"
                            :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                            :maxlength="item.subItem.maxLength"
                            type="textarea"
                          ></el-input>
                          <span slot="reference">
                            <el-link :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled" type="info"
                              ><i class="el-icon-more" />
                            </el-link>
                          </span>
                        </el-popover>
                      </template>
                    </el-input>
                  </div>
                  <div
                    v-else
                    :class="defaultStatusClass(item, row) + ' text'"
                    style="height: 100%; width: 100%"
                    v-html="
                      item.formatter
                        ? item.needOtherColumn
                          ? item.formatter(row)
                          : item.formatter(row[item.prop])
                        : row[item.prop] || row[item.prop] === 0
                        ? row[item.prop]
                        : '&nbsp;'
                    "
                  ></div>
                  <div v-if="item.subItem && item.subItem.copyAndPaste" style="display: flex; flex-direction: column; justify-content: center">
                    <el-tooltip content="复制" effect="dark" placement="right">
                      <el-link
                        :disabled="isShow"
                        :underline="false"
                        class="vg_cursor"
                        icon="el-icon-document-copy"
                        @click.stop="copyCell(`${item.prop}[copyDataFlag]${row[item.prop]}[copyDataFlag]${row.index}[copyDataFlag]${typeof row[item.prop]}`)"
                      ></el-link>
                    </el-tooltip>
                    <span style="height: 9px"></span>
                    <el-tooltip content="粘贴" effect="dark" placement="right">
                      <el-link
                        :disabled="isShow"
                        :underline="false"
                        class="vg_cursor"
                        icon="el-icon-finished"
                        @click.stop="pasteCell(item.prop, row.index)"
                      ></el-link>
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <!--默认显示-->
              <template v-slot:default="{ row, rowIndex }">
                <div
                  v-if="item.currColumnVisible ? item.currColumnVisible(row) : true"
                  :style="item.align ? 'justify-content:' + item.align : 'justify-content:center'"
                  class="flexV internalMarking"
                >
                  <!--通用插槽-->
                  <div v-if="$scopedSlots[item.prop] && openDefaultCommonSlots" class="internalMarking" style="height: 100%; width: 100%">
                    <slot :name="item.prop" v-bind="{ row: row, $index: rowIndex }" />
                  </div>
                  <div v-else-if="$scopedSlots[`default-${item.prop}`]" class="internalMarking" style="height: 100%; width: 100%">
                    <slot :name="`default-${item.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                  </div>
                  <!--图片显示-->
                  <div
                    v-else-if="item.subItem && (item.subItem.type === 'image' || item.subItem.type === 'uploadImage')"
                    class="flexHV internalMarking"
                    style="height: 100%; width: 100%"
                  >
                    <el-image
                      ref="previewImg"
                      :lazy="item.subItem.lazy"
                      :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(row) : item.subItem.image]"
                      :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(row) : item.subItem.thumbnail"
                      class="flexHV"
                      style="height: 80px; width: 80px"
                      @click="
                        event => {
                          $refs.previewImg.showViewer = true;
                          event.stopPropagation();
                        }
                      "
                    >
                      <span slot="error" v-html="'暂无'"></span>
                    </el-image>
                  </div>
                  <!--选择框-->
                  <div
                    v-else-if="item.subItem && item.subItem.type === 'newSelect'"
                    :class="defaultStatusClass(item, row) + ' internalMarking'"
                    style="height: 100%; width: 100%"
                    v-html="item.subItem.options[row[item.prop]] ? item.subItem.options[row[item.prop]].label : '&nbsp;'"
                  />
                  <div v-else :class="defaultStatusClass(item, row) + ' text'">
                    <div style="height: 100%; width: 100%; overflow: hidden; text-overflow: ellipsis" v-html="defaultText(item, row)"></div>
                  </div>
                  <div v-if="item.subItem && item.subItem.copyAndPaste" style="display: flex; flex-direction: column; justify-content: center">
                    <el-tooltip content="复制" effect="dark" placement="right">
                      <el-link
                        :disabled="isShow"
                        :underline="false"
                        class="vg_cursor"
                        icon="el-icon-document-copy"
                        @click.stop="copyCell(`${item.prop}[copyDataFlag]${row[item.prop]}[copyDataFlag]${row.index}[copyDataFlag]${typeof row[item.prop]}`)"
                      ></el-link>
                    </el-tooltip>
                    <span style="height: 6px"></span>
                    <el-tooltip content="粘贴" effect="dark" placement="right">
                      <el-link
                        :disabled="isShow"
                        :underline="false"
                        class="vg_cursor"
                        icon="el-icon-finished"
                        @click.stop="pasteCell(item.prop, row.index)"
                      ></el-link>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </ux-table-column>
            <!--多级表头-->
            <ux-table-column v-else :title="item.label" align="center">
              <ux-table-column
                v-for="(secondary, sIndex) in item.secondaryHeader"
                :key="sIndex"
                :align="secondary.align ? secondary.align : 'center'"
                :edit-render="{ autofocus: '.el-input__inner' }"
                :field="secondary.prop"
                :remote-sort="!!secondary.remoteSort"
                :resizable="true"
                :sortable="secondary.sortable"
                :title="secondary.label"
                :visible="secondary.visible"
                :min-width="
                  secondary.widthAuto
                    ? 'auto'
                    : secondary.labelWidth
                    ? secondary.labelWidth
                    : secondary[`sum_${secondary.prop}`] && secondary[`sum_${secondary.prop}`].toString().length > secondary.label.length * 2
                    ? secondary[`sum_${secondary.prop}`].toString().length * 10
                    : secondary.label.length * 1.3 * 20
                "
              >
                <template v-slot:header>
                  <div v-if="$scopedSlots[`header-${secondary.prop}`]" style="width: 100%">
                    <slot :name="`header-${secondary.prop}`" v-bind="searchForm" />
                  </div>
                  <div v-else :style="needSearch ? 'height:100px' : 'height:34px'" class="flexHV" @click="e => e.stopPropagation()">
                    <div>
                      <div :class="`${secondary.subItem ? (secondary.subsItem.required ? 'vg_deep_red flexHV' : 'flexHV') : 'flexHV'}`" style="height: 34px">
                        {{ secondary.label }}
                      </div>
                      <div v-if="needSearch">
                        <div v-if="secondary.secondaryType === 'slot'" style="width: 100%">
                          <slot :name="`header-search-${secondary.prop}`" v-bind="{ searchForm }" />
                        </div>
                        <el-date-picker
                          v-if="secondary.secondaryType === 'date'"
                          v-model="searchForm[secondary.otherProp ? secondary.otherProp : secondary.prop]"
                          :disabled="secondary.input === false"
                          :placeholder="secondary.input === false ? '' : '请选择'"
                          :value-format="secondary.valueFormat"
                          clearable
                          show-word-limit
                          size="mini"
                          type="date"
                          @change="getData"
                        />
                        <el-input
                          v-if="secondary.secondaryType === 'input'"
                          v-model="searchForm[secondary.otherProp ? secondary.otherProp : secondary.prop]"
                          :disabled="secondary.input === false"
                          :placeholder="secondary.input === false ? '' : '请输入'"
                          clearable
                          size="mini"
                          @clear="getData"
                          @input="val => (secondary.searchInput ? secondary.searchInput(val, searchForm, secondary.prop) : '')"
                          @keyup.enter.native="getData(index)"
                        />
                        <el-select
                          v-if="secondary.secondaryType === 'select'"
                          v-model="searchForm[secondary.otherProp ? secondary.otherProp : secondary.prop]"
                          :allow-create="secondary.allowCreate"
                          :collapse-tags="secondary.collapseTags"
                          :disabled="secondary.input === false"
                          :filter-method="secondary.filterMethod"
                          :filterable="secondary.filterable"
                          :loading="secondary.loading"
                          :loading-text="secondary.loadingText"
                          :multiple="secondary.multiple"
                          :multiple-limit="secondary.multipleLimit"
                          :name="secondary.name"
                          :no-data-text="secondary.noDataText"
                          :no-match-text="secondary.noMatchText"
                          :placeholder="secondary.input === false ? '' : '请选择'"
                          :popper-class="secondary.popperClass"
                          :remote="secondary.remote"
                          :remote-method="secondary.remoteMethod"
                          clearable
                          size="mini"
                          @clear="getData(index)"
                          @visible-change="val => headerSearchVisibleChange(val, secondary, index)"
                          @remove-tag="typeof secondary.removeTag === 'function' ? secondary.removeTag($event) : () => {}"
                        >
                          <el-option
                            v-for="(secondary, index) in secondary.options"
                            :key="`${tableRowKey}select${secondary.value}${index}`"
                            :disabled="secondary.disabled"
                            :label="secondary.label"
                            :style="selectOptionStyle"
                            :value="secondary.value"
                          ></el-option>
                        </el-select>
                      </div>
                      <!--                      <div v-if="needSearch">
                        <el-date-picker
                          v-if="secondary.itemType === 'date'"
                          v-model="searchForm[secondary.prop]"
                          :disabled="secondary.input === false"
                          :placeholder="secondary.input === false ? '' : '请选择'"
                          :value-format="secondary.valueFormat"
                          clearable
                          show-word-limit
                          size="mini"
                          type="date"
                          @change="getData"
                        />
                        <el-input
                          v-if="secondary.itemType === 'input'"
                          v-model="searchForm[secondary.prop]"
                          :disabled="secondary.input === false"
                          :placeholder="secondary.input === false ? '' : '请输入'"
                          clearable
                          size="mini"
                          @clear="getData"
                          @input="val => (secondary.searchInput ? secondary.searchInput(val, searchForm, secondary.prop) : '')"
                          @keyup.enter.native="getData(sIndex)"
                        />
                        <el-select
                          v-if="secondary.itemType === 'select'"
                          v-model="searchForm[secondary.prop]"
                          :allow-create="secondary.allowCreate"
                          :collapse-tags="secondary.collapseTags"
                          :disabled="secondary.input === false"
                          :filter-method="secondary.filterMethod"
                          :filterable="secondary.filterable"
                          :loading="secondary.loading"
                          :loading-text="secondary.loadingText"
                          :multiple="secondary.multiple"
                          :multiple-limit="secondary.multipleLimit"
                          :name="secondary.name"
                          :no-data-text="secondary.noDataText"
                          :no-match-text="secondary.noMatchText"
                          :placeholder="secondary.input === false ? '' : '请选择'"
                          :popper-class="secondary.popperClass"
                          :remote="secondary.remote"
                          :remote-method="secondary.remoteMethod"
                          clearable
                          size="mini"
                          @change="getData"
                          @visible-change="typeof secondary.visibleChange === 'function' ? secondary.visibleChange($event) : () => {}"
                          @remove-tag="typeof secondary.removeTag === 'function' ? secondary.removeTag($event) : () => {}"
                        >
                          <el-option
                            v-for="secondary in secondary.options"
                            :key="`${tableRowKey}select${secondary.value}`"
                            :disabled="secondary.disabled"
                            :label="secondary.label"
                            :style="selectOptionStyle"
                            :value="secondary.value"
                          ></el-option>
                        </el-select>
                      </div>-->
                    </div>
                  </div>
                </template>
                <template v-slot:edit="{ row, rowIndex }">
                  <div
                    v-if="secondary.currColumnVisible ? secondary.currColumnVisible(row) : true"
                    style="width: 100%; height: 100%"
                    @click="e => e.stopPropagation()"
                  >
                    <div v-if="$scopedSlots[secondary.prop]">
                      <slot :name="secondary.prop" v-bind="{ row: row, $index: rowIndex }" />
                    </div>
                    <span v-else-if="$scopedSlots[`special-${secondary.prop}`]">
                      <slot :name="`special-${secondary.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                    </span>
                    <div v-else-if="secondary.subItem">
                      <div v-if="secondary.subsItem.type === 'input'">
                        <el-input
                          v-if="computedData && computedData[secondary.prop]"
                          :value="typeof computedData[secondary.prop] === 'function' ? computedData[secondary.prop](rowIndex) : computedData[secondary.prop]"
                          disabled
                          placeholder="自动"
                          size="small"
                        />
                        <el-input
                          v-else
                          v-model="row[secondary.prop]"
                          :autosize="secondary.subsItem.autosize"
                          :clearable="secondary.subsItem.clearable"
                          :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                          :max="typeof secondary.subsItem.max === 'function' ? secondary.subsItem.max(row) : secondary.subsItem.max"
                          :maxlength="secondary.subsItem.maxLength"
                          :min="typeof secondary.subsItem.min === 'function' ? secondary.subsItem.min(row) : secondary.subsItem.min"
                          :placeholder="secondary.subsItem.placeholder ? secondary.subsItem.placeholder : '请输入'"
                          :type="secondary.subsItem.inputType"
                          clearable
                          show-word-limit
                          size="small"
                          @blur="event => (secondary.subsItem.blur ? secondary.subsItem.blur(event, row) : () => {})"
                          @change="event => (secondary.subsItem.change ? secondary.subsItem.change(event, row) : () => {})"
                          @clear="row[secondary.prop] = null"
                          @input="event => (secondary.subsItem.input ? secondary.subsItem.input(event, row) : () => {})"
                        >
                          <template v-if="$scopedSlots[`subItem-${secondary.prop}`]" slot="append">
                            <slot :name="`subItem-${secondary.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                          </template>
                        </el-input>
                      </div>
                      <div v-if="secondary.subsItem.type === 'image'">
                        <el-image
                          :preview-src-list="[typeof secondary.subsItem.image === 'function' ? secondary.subsItem.image(row) : secondary.subsItem.image]"
                          :src="typeof secondary.subsItem.thumbnail === 'function' ? secondary.subsItem.thumbnail(row) : secondary.subsItem.thumbnail"
                          lazy
                          style="height: 80px; width: 80px"
                        />
                      </div>
                      <div v-if="secondary.subsItem.type === 'uploadImage'" style="display: flex">
                        <el-image
                          :preview-src-list="[typeof secondary.subsItem.image === 'function' ? secondary.subsItem.image(row) : secondary.subsItem.image]"
                          :src="typeof secondary.subsItem.thumbnail === 'function' ? secondary.subsItem.thumbnail(row) : secondary.subsItem.thumbnail"
                          class="flexHV"
                          lazy
                          style="height: 80px; width: 80px"
                        >
                          <span slot="error" v-html="'暂无'"></span>
                        </el-image>
                        <el-upload
                          :ref="`uploadMultiple${rowIndex}`"
                          :action="uploadUrl"
                          :before-upload="beforeAvatarUpload"
                          :data="dataBody"
                          :on-success="res => handleAvatarSuccess(res, row, secondary.subsItem.imageField, secondary.subsItem.thumbnailField)"
                          :show-file-list="false"
                        >
                          <el-link :disabled="isShow" class="vg_cursor" type="primary">点击上传</el-link>
                        </el-upload>
                        <div id="preview" class="flexHV" @paste="e => handlePaste(e, rowIndex)">
                          <el-link slot="trigger" :disabled="isShow" class="vg_cursor" type="primary">粘贴</el-link>
                        </div>
                      </div>
                      <el-date-picker
                        v-if="secondary.subsItem.type === 'date'"
                        v-model="row[secondary.prop]"
                        :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                        clearable
                        placeholder="请选择"
                        show-word-limit
                        size="small"
                        type="date"
                        value-format="timestamp"
                        @change="event => (secondary.subsItem.change ? secondary.subsItem.change(event, row, secondary.subsItem.options) : () => {})"
                      >
                      </el-date-picker>
                      <el-select
                        v-if="secondary.subsItem.type === 'select'"
                        v-model="row[secondary.prop]"
                        :allow-create="secondary.subsItem.allowCreate"
                        :clearable="secondary.subsItem.clearable"
                        :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                        :filter-method="secondary.subsItem.filterMethod"
                        :filterable="secondary.subsItem.filterable ? secondary.subsItem.filterable : true"
                        :loading="secondary.subsItem.loading"
                        :loading-text="secondary.subsItem.loadingText"
                        :multiple="secondary.subsItem.multiple"
                        :multiple-limit="secondary.subsItem.multipleLimit"
                        :name="secondary.subsItem.name"
                        :no-data-text="secondary.subsItem.noDataText"
                        :no-match-text="secondary.subsItem.noMatchText"
                        :placeholder="secondary.subsItem.placeholder"
                        :popper-class="secondary.subsItem.popperClass"
                        :remote="secondary.subsItem.remote"
                        :remote-method="secondary.subsItem.remoteMethod"
                        automatic-dropdown
                        clearable
                        size="small"
                        @change="event => (secondary.subsItem.change ? secondary.subsItem.change(event, row, secondary.subsItem.options) : () => {})"
                        @visible-change="typeof secondary.subsItem.visibleChange === 'function' ? secondary.subsItem.visibleChange($event) : () => {}"
                        @remove-tag="typeof secondary.subsItem.removeTag === 'function' ? secondary.subsItem.removeTag($event) : () => {}"
                      >
                        <el-option
                          v-for="(subOption, subOptionIndex) in secondary.subsItem.options"
                          :key="`subSelect${subOption.value}${subOptionIndex}`"
                          :disabled="subOption.disabled"
                          :label="subOption.label"
                          :style="selectOptionStyle"
                          :value="subOption.value"
                        />
                      </el-select>
                      <el-select
                        v-if="secondary.subsItem.type === 'newSelect'"
                        v-model="row[secondary.prop]"
                        :allow-create="secondary.subsItem.allowCreate"
                        :clearable="secondary.subsItem.clearable"
                        :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                        :filter-method="secondary.subsItem.filterMethod"
                        :filterable="secondary.subsItem.filterable ? secondary.subsItem.filterable : true"
                        :loading="secondary.subsItem.loading"
                        :loading-text="secondary.subsItem.loadingText"
                        :multiple="secondary.subsItem.multiple"
                        :multiple-limit="secondary.subsItem.multipleLimit"
                        :name="secondary.subsItem.name"
                        :no-data-text="secondary.subsItem.noDataText"
                        :no-match-text="secondary.subsItem.noMatchText"
                        :placeholder="secondary.subsItem.placeholder"
                        :popper-class="secondary.subsItem.popperClass"
                        :remote="secondary.subsItem.remote"
                        :remote-method="secondary.subsItem.remoteMethod"
                        automatic-dropdown
                        clearable
                        size="small"
                        @change="event => (secondary.subsItem.change ? secondary.subsItem.change(event, row, secondary.subsItem.options) : () => {})"
                        @visible-change="typeof secondary.subsItem.visibleChange === 'function' ? secondary.subsItem.visibleChange($event) : () => {}"
                        @remove-tag="typeof secondary.subsItem.removeTag === 'function' ? secondary.subsItem.removeTag($event) : () => {}"
                      >
                        <el-option
                          v-for="(subOption, index) in Object.values(secondary.subsItem.options)"
                          :key="`subSelect${subOption.value}${index}`"
                          :disabled="subOption.disabled"
                          :label="subOption.label"
                          :style="selectOptionStyle"
                          :value="subOption.value"
                        />
                      </el-select>
                      <el-input
                        v-else-if="secondary.subsItem.type === 'popoverInput'"
                        v-model="row[secondary.prop]"
                        :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                        :maxlength="secondary.subsItem.maxLength"
                        show-word-limit
                        size="small"
                      >
                        <template slot="append">
                          <el-popover placement="bottom" trigger="click" width="600">
                            <el-input
                              v-model="row[secondary.prop]"
                              :autosize="{ minRows: 5, maxRows: 10 }"
                              :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                              :maxlength="secondary.subsItem.maxLength"
                              type="textarea"
                            ></el-input>
                            <span slot="reference">
                              <el-link
                                :disabled="typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled"
                                class="vg_cursor"
                                type="info"
                                ><i class="el-icon-more"></i
                              ></el-link>
                            </span>
                          </el-popover>
                        </template>
                      </el-input>
                    </div>
                    <span
                      v-else
                      v-html="
                        secondary.formatter
                          ? secondary.needOtherColumn
                            ? secondary.formatter(row)
                            : secondary.formatter(row[secondary.prop])
                          : row[secondary.prop]
                          ? row[secondary.prop]
                          : '&nbsp;'
                      "
                    >
                    </span>
                  </div>
                </template>
                <template v-slot:default="{ row, rowIndex }">
                  <div
                    v-if="secondary.currColumnVisible ? secondary.currColumnVisible(row) : true"
                    :style="secondary.align ? 'justify-content:' + secondary.align : 'justify-content:center'"
                    class="flexV"
                  >
                    <div v-if="$scopedSlots[secondary.prop]" style="height: 100%; width: 100%">
                      <slot :name="secondary.prop" v-bind="{ row: row, $index: rowIndex }" />
                    </div>
                    <div v-else-if="$scopedSlots[`default-${secondary.prop}`]" style="height: 100%; width: 100%">
                      <slot :name="`default-${secondary.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                    </div>
                    <div
                      v-else-if="secondary.subItem && (secondary.subsItem.type === 'image' || secondary.subsItem.type === 'uploadImage')"
                      class="flexHV"
                      style="height: 100%; width: 100%"
                    >
                      <el-image
                        ref="previewImg"
                        :lazy="secondary.subsItem.lazy"
                        :preview-src-list="[typeof secondary.subsItem.image === 'function' ? secondary.subsItem.image(row) : secondary.subsItem.image]"
                        :src="typeof secondary.subsItem.thumbnail === 'function' ? secondary.subsItem.thumbnail(row) : secondary.subsItem.thumbnail"
                        class="flexHV"
                        style="height: 80px; width: 80px"
                        @click="
                          event => {
                            $refs.previewImg.showViewer = true;
                            event.stopPropagation();
                          }
                        "
                      >
                        <span slot="error" v-html="'暂无'"></span>
                      </el-image>
                    </div>
                    <div
                      v-else-if="secondary.subItem && secondary.subsItem.type === 'newSelect'"
                      :class="defaultStatusClass(secondary, row)"
                      style="height: 100%; width: 100%"
                      v-html="secondary.subsItem.options[row[secondary.prop]] ? secondary.subsItem.options[row[secondary.prop]].label : '&nbsp;'"
                    ></div>
                    <div
                      v-else
                      :class="defaultStatusClass(secondary, row)"
                      style="height: 100%; width: 100%; overflow: hidden; text-overflow: ellipsis"
                      v-html="
                        secondary.formatter
                          ? secondary.needOtherColumn
                            ? secondary.formatter(row)
                            : secondary.formatter(row[secondary.prop])
                          : row[secondary.prop] || row[secondary.prop] === 0
                          ? row[secondary.prop]
                          : '&nbsp;'
                      "
                    ></div>
                  </div>
                </template>
              </ux-table-column>
            </ux-table-column>
          </template>
        </ux-grid>
      </div>
      <el-pagination
        v-if="needPagination"
        ref="pubPagination"
        :current-page="currentPage"
        :layout="`total,${needPageSizes ? 'sizes,' : ''} prev, pager, next`"
        :page-size="pageSize"
        :total="totalPage"
        background
        class="tablePagination"
        :page-sizes="[50, 100, 150, 200]"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      >
      </el-pagination>
    </el-form>
  </div>
</template>

<script>
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import { BigNumber } from 'bignumber.js';
import { imgeAPI } from '@api/modules/imge';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { pasteImage } from '@api/public';

export default {
  name: 'DynamicUTable',
  components: { Sortable },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    isShow: { type: Boolean, default: false },
    selectable: { type: Function, default: () => true },
    computedData: {
      type: Object,
      default: () => {}
    },
    otherComputedData: {
      type: Object,
      default: () => {}
    },
    columns: { type: Array, default: () => [] },
    stripe: { type: Boolean, default: true },
    showSummary: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
    needAllColumnSummary: { type: Boolean, default: false },
    skipSummaryIndex: { type: Array, default: () => [] },
    needClosedUpdate: { type: Boolean, default: false },
    needAllColumnSummaryToFixed: { type: Number, default: 4 },
    summariesColumns: { type: Array, default: () => [] },
    needSummaryValues: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => ({}) },
    tableData: { type: Array, default: () => [] },
    loadFlag: { type: Boolean, default: false },
    needPagination: { type: Boolean, default: true },
    needPageSizes: { type: Boolean, default: false },
    expandFixed: { type: String, default: 'left' },
    checkBoxFixed: { type: String, default: 'left' },
    indexFixed: { type: String, default: 'left' },
    needExpand: { type: Boolean, default: false },
    isExpand: { type: Boolean, default: false },
    needCheckBox: { type: Boolean, default: true },
    needIndex: { type: Boolean, default: true },
    needSort: { type: Boolean, default: false },
    needSummation: { type: Boolean, default: false },
    needSearch: { type: Boolean, default: false },
    needFixedHeight: { type: Boolean, default: false },
    needChangeColumnProperties: { type: Boolean, default: false },
    needToVerifyCharacterLength: { type: Boolean, default: false },
    rowMove: { type: Boolean, default: false },
    columnMove: { type: Boolean, default: true },
    dbClickJp: {
      type: Function,
      default: () => {}
    },
    rowClick: {
      type: Function,
      default: () => {}
    },
    rowClassName: {
      type: Function,
      default: () => {}
    },
    rowKey: { type: Function || String, default: null },
    rowStyle: { type: Function, default: ({ row, rowIndex }) => Vue.set(row, 'index', rowIndex) },
    tableSelect: { type: Function, default: () => null },
    tableSelectionChange: { type: Function, default: () => null },
    tableSelectAll: { type: Function, default: () => null },
    totalPage: { type: Number, default: 0 },
    pageSize: { type: Number, default: 20 },
    maxHeight: { type: Number, default: 400 },
    getTableData: { type: Function, default: () => null },
    otherHeaderCellStyle: {
      type: Function,
      default: () => {}
    },
    editClosed: {
      type: Function,
      default: () => {}
    },
    editActived: {
      type: Function,
      default: () => {}
    },
    cellMouseEnter: {
      type: Function,
      default: () => {}
    },
    activeMethod: {
      type: Function,
      default: () => true
    },
    autoHeight: { type: Boolean, default: false },
    selectOptionStyle: String,
    tableRowKey: String,
    cellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'font-size': '14px'
        };
      }
    },
    indexTitle: String,
    openDefaultCommonSlots: { type: Boolean, default: true },
    isCopyColumn: { type: Boolean, default: true }
  },
  computed: {
    defaultStatusClass(item, row) {
      return (item, row) => {
        if (item.subItem) {
          const disabled = typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled;
          return disabled || this.isShow ? 'my-input-sc my-input-sc-disabled' : 'my-input-sc my-input-sc-show';
        } else return '';
      };
    },
    tableColumn() {
      return this.columns;
    },
    rules() {
      if (this.isShow) return {};
      return this.columns.reduce((temp, { prop, label, subItem }) => {
        temp[prop] = [
          {
            validator: args => {
              if (subItem) {
                const required = subItem.required || false;
                if (required && ['', undefined, null].includes(args.cellValue)) return new Error();
                const disabled = typeof subItem.disabled === 'function' ? subItem.disabled(args.row) : subItem.disabled;
                if (this.needToVerifyCharacterLength && subItem.maxLength && !disabled && args.cellValue) {
                  if (args.cellValue.length > subItem.maxLength) return new Error();
                }
              }
            }
          }
        ];
        return temp;
      }, {});
    },
    needChangeColumnPropertiesStyle() {
      let temp = '100%';
      if (this.needSearch || this.needChangeColumnProperties) temp = '100px';
      if (!this.needSearch && this.needChangeColumnProperties) temp = '66px';
      if (!this.needSearch && !this.needChangeColumnProperties) temp = '34px';
      if (this.isSecondaryHeader) temp = '100%';
      return { height: temp };
    },
    originalObject() {
      let obj = this.columns.reduce((obj, { prop }) => {
        obj[prop] = '';
        return obj;
      }, {});
      obj.destroy_flag = null;
      return obj;
    }
  },
  data() {
    return {
      isMouseLeft: false,
      searchForm: {
        page_no: 1
      },
      tableKey: '',
      tableMaxHeight: 0,
      currentPage: 1,
      uploadUrl: (process.env.NODE_ENV === 'production' ? '/erp' : '') + imgeAPI.uploadImage,
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      observer: new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // 交叉区域比例
      }),
      scrollTop: 0,
      scrollLeft: 0,
      isSecondaryHeader: false,
      popoverInputObj: {},
      columnsFixedStatus: {},
      operationBtnShow: false,
      tableStatus: false,
      thisLoadFlag: false,
      sortStatus: this.$route.path.includes('edit') || this.$route.path.includes('add')
    };
  },
  created() {
    this.initSearchForm();
  },
  async mounted() {
    // await this.addObject();
    await this.rowDrop();
    await this.columnDrop();
    setTimeout(() => {
      this.getTableMaxHeight();
      if (this.$refs.multiTable) this.observer.observe(this.$refs.multiTable.$el);
    }, 1000);
  },
  beforeDestroy() {
    this.observer.disconnect();
    console.log('observer disconnected!');
  },
  methods: {
    extendRowStyle({ row, rowIndex }) {
      Vue.set(row, 'index', rowIndex);
      return this.rowStyle({ row, rowIndex });
    },
    initSearchForm() {
      this.columns.forEach(({ prop, otherProp }) => {
        if (this.needSearch) this.searchForm = { ...this.searchForm, [prop]: null, [otherProp]: null };
        this.columnsFixedStatus = { ...this.columnsFixedStatus, [prop]: false };
      });
      this.searchForm.page_no = 1;
      this.searchForm.page_size = this.pageSize;
    },
    handleIntersection(entries) {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) this.$refs.multiTable.pagingScrollTopLeft(this.scrollTop, this.scrollLeft - 10);
      });
    },
    getTableMaxHeight() {
      if (this.autoHeight) {
        this.tableMaxHeight = null;
        return;
      }
      if (this.maxHeight !== 400 || this.tableMaxHeight === 0) {
        if (this.needFixedHeight || this.$route.path.includes('list')) {
          const { y } = this.$refs.multiTable?.$el.getBoundingClientRect() || 0;
          // 40 = padding | 77 = pagination height
          this.tableMaxHeight = window.innerHeight - y - 40 - 77;
          this.$emit('changeTableMaxHeight', this.tableMaxHeight);
          return;
        }
        this.tableMaxHeight = this.maxHeight + (this.showSummary ? 44 : 0);
      }
    },
    addObject() {
      // this.originalObject = this.columns.reduce((obj, { prop }) => {
      //   obj[prop] = '';
      //   return obj;
      // }, {});
    },
    reload(data) {
      this.thisLoadFlag = true;
      this.$refs.multiTable?.reloadData(data ?? this.tableData);
      setTimeout(() => {
        this.thisLoadFlag = false;
      }, 1000);
    },
    loadData(data) {
      this.$refs.multiTable?.loadData(data ?? this.tableData);
    },
    rowDrop() {
      if (this.rowMove === false) return;
      this.$nextTick(() => {
        let plxTable = this.$refs.multiTable;
        if (plxTable) {
          this.sortable = Sortable.create(plxTable.$el.querySelector('.body--wrapper .elx-table--body tbody'), {
            handle: '.elx-body--row',
            delay: 300,
            onEnd: ({ item, newIndex, oldIndex }) => {
              let currRow = this.tableData.splice(oldIndex, 1)[0];
              this.tableData.splice(newIndex, 0, currRow);
            }
          });
        }
      });
    },
    columnDrop() {
      if (this.columnMove === false) return;
      this.$nextTick(() => {
        let plxTable = this.$refs.multiTable;
        if (plxTable) {
          let plxFirstRow = plxTable.$el.querySelector('.elx-table--header .elx-header--row:nth-child(1)');
          this.sortable = Sortable.create(plxFirstRow, {
            handle: '.elx-header--column:not(.col--fixed)',
            ghostClass: 'dragColbg',
            chosenClass: 'dragColbg',
            animation: 300,
            invertSwap: false,
            delay: 300,
            onEnd: ({ item, newIndex, oldIndex }) => {
              if (newIndex === oldIndex) return;
              let { collectColumn, tableColumn } = plxTable.getTableColumn();
              let oldColumnIndex = collectColumn.findIndex(({ title }) => title === tableColumn[oldIndex].title);
              let newColumnIndex = collectColumn.findIndex(({ title }) => title === tableColumn[newIndex].title);
              let targetThElem = item;
              let wrapperElem = targetThElem.parentNode;
              let newColumn = collectColumn[newIndex];
              if (newColumn.fixed) {
                if (newIndex > oldIndex) {
                  wrapperElem.insertBefore(targetThElem, wrapperElem.children[oldIndex]);
                } else {
                  wrapperElem.insertBefore(wrapperElem.children[oldIndex], targetThElem);
                }
                return;
              }
              let currRow = collectColumn.splice(oldColumnIndex, 1)[0];
              collectColumn.splice(newColumnIndex, 0, currRow);
              plxTable.loadColumn(cloneDeep(collectColumn));
            }
          });
        }
      });
    },
    sortChange({ order, prop, column }) {
      this.thisLoadFlag = true;
      if (!this.sortStatus) {
        this.$refs.multiTable.sort(prop, order);
        this.searchForm.column = order ? prop : '';
        this.searchForm.order = order || '';
        if (order) this.getData();
      }
      const [top, left] = cloneDeep([this.scrollTop, this.scrollLeft]);
      setTimeout(() => {
        this.$refs.multiTable.pagingScrollTopLeft(top, left);
      }, 50);
      setTimeout(() => {
        this.$refs.multiTable.updateData();
        this.thisLoadFlag = false;
      }, 1000);
    },
    getData(index) {
      if (this.needPagination) {
        this.currentPage = 1;
        this.searchForm.page_no = 1;
        this.searchForm.page_size = this.pageSize;
      }
      this.$emit('getTableData', index);
    },
    resetFields() {
      this.searchForm = !this.needPagination ? {} : { page_no: 1, page_size: this.pageSize };
      this.currentPage = 1;
      setTimeout(() => this.$refs.multiTable?.clearSort());
    },
    doLayout() {
      this.$refs.multiTable.doLayout();
    },
    updateData() {
      this.$refs.multiTable?.updateData();
    },
    clearSelection() {
      this.$refs.multiTable?.clearSelection();
    },
    sizeChangeHandle(val) {
      this.searchForm.page_size = val;
      this.$emit('getTableData');
      this.$nextTick(() => {
        setTimeout(() => (this.scrollTop = 0));
      });
    },
    currentChangeHandle(val) {
      this.searchForm.page_no = val;
      this.currentPage = val;
      this.$emit('getTableData');
      this.$nextTick(() => {
        setTimeout(() => (this.scrollTop = 0));
      });
    },
    scroll({ scrollTop, scrollLeft }) {
      this.scrollTop = scrollTop;
      this.scrollLeft = scrollLeft;
    },
    getAllSummaries({ columns, data }) {
      const sums = columns.map((column, index) => {
        if (index === 0) return '合计';
        if (this.skipSummaryIndex.includes(index)) return '';
        const values = data.map(item => Number(item[column.property]));
        const sum = values.reduce((x, item) => (item ? x.plus(item) : x), new BigNumber(0)).toNumber();
        return Number(sum) ? Number(sum).toLocaleString() : sum || '';
      });
      return [sums];
    },
    getSummaries({ columns, data }) {
      const sums = [];
      const needEmitIndexArray = []; // 需要给父组件传递索引的数组
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (this.needSummaryValues.includes(column.property)) needEmitIndexArray.push(index);
        let values = data.map(item => Number(item[column.property]));
        if (this.summariesColumns.includes(column.property)) {
          let initValue = new BigNumber(0);
          let temp = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(this.needAllColumnSummaryToFixed);
          sums[index] = Number(temp) ? Number(temp).toLocaleString(undefined, { maximumSignificantDigits: 21 }) : temp;
        }
      });
      this.needSummaryValues.forEach((item, index) => {
        this.$emit(`getSummary_${item}`, sums[needEmitIndexArray[index]]?.replace(/,/g, '') || '');
      });
      return [sums];
    },
    async fullValidateMsg() {
      let validationResults = await this.$refs.multiTable.fullValidate();
      if (!validationResults) return '';
      const arr = Object.values(validationResults);
      const masArr = arr.flatMap(errList => errList.map(params => params.column.title));
      if (masArr.length === 0) return '';
      return [...new Set(masArr)];
    },
    handleAvatarSuccess(res, row, imageField = 'imge_id', thumbnailField = 'imge_url') {
      if (res.code === 0) {
        this.$set(row, imageField, res.data.imge_id);
        this.$set(row, thumbnailField, res.data.imge_url);
      }
    },
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 3;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 3MB!');
        return false;
      }
    },
    handlePaste(event, index) {
      pasteImage({ ref: this.$refs[`uploadMultiple${index}`][0], index });
    },
    headerCellStyle({ columnIndex, column }) {
      if (this.columns[columnIndex]?.secondaryHeader) {
        this.isSecondaryHeader = true;
      }
      return { 'background-color': '#f5f7fa', ...this.otherHeaderCellStyle(column) };
    },
    defaultText(item, row) {
      const value = item.formatter ? (item.needOtherColumn ? item.formatter(row) : item.formatter(row[item.prop])) : row[item.prop];
      return value || value === 0 ? value + '' : '';
    },
    saveColumnsFixedStatus() {
      for (const key in this.columnsFixedStatus) {
        let status = this.columnsFixedStatus[key];
        let find = this.tableColumn.find(({ prop }) => prop === key);
        if (find) find.fixed = status ? 'left' : null;
      }
      this.operationBtnShow = false;
      this.reloadTable();
    },
    copyColumn({ prop, itemType, formatter, needOtherColumn, subItem }) {
      let str = '';
      this.tableData.forEach(item => {
        if (formatter && !needOtherColumn) str += formatter(item[prop]) + '\n';
        else if (needOtherColumn) str += item ? formatter(item) + '\n' : '\n';
        else if (subItem && subItem.type === 'select') str += (subItem.options.find(x => x.value === item[prop])?.label ?? '') + '\n';
        else if (subItem && subItem.type === 'newSelect') str += (subItem.options[item[prop]]?.label ?? '') + '\n';
        else str += (item[prop] ? item[prop] : '') + '\n';
      });
      navigator.clipboard.writeText(str);
    },
    reloadTable() {
      this.tableStatus = true;
      setTimeout(() => {
        this.tableStatus = false;
        this.columnDrop();
      }, 800);
    },
    refreshColumn() {
      setTimeout(() => {
        this.$refs.multiTable.refreshColumn();
      });
    },
    copyCell(text) {
      navigator.clipboard.writeText(text);
    },
    async pasteCell(column, index) {
      try {
        const text = await navigator.clipboard.readText();
        const textSplit = text.split('[copyDataFlag]');
        if (textSplit.length > 1 && textSplit[0] === column) {
          const oldIndex = Number(textSplit[2]);
          if (oldIndex === index) return;
          this.changeData({ rangeIndexArr: [oldIndex, index], textSplit, column });
        }
      } catch (err) {
        console.error('无法读取剪贴板: ', err);
      }
    },
    changeData({ rangeIndexArr, textSplit, column }) {
      let visibleData = this.$refs.multiTable.getTableData().visibleData;
      let index1 = visibleData.findIndex(x => x.index === rangeIndexArr[0]);
      let index2 = visibleData.findIndex(x => x.index === rangeIndexArr[1]);
      const changeRangeIndexArr = index1 > index2 ? [index2, index1] : [index1, index2];
      let { subItem, prop } = this.tableColumn.find(x => x.prop === column);
      visibleData.forEach(async (item, tIndex) => {
        if (tIndex >= changeRangeIndexArr[0] && tIndex <= changeRangeIndexArr[1]) {
          const value = textSplit[3] === 'number' ? Number(textSplit[1]) : textSplit[1];
          await this.$set(item, column, JSON.parse(JSON.stringify(value)));
          if (subItem.change) await subItem.change(value, item);
          if (subItem.copyChange) {
            if (typeof subItem.copyChange === 'function') await subItem.copyChange(value, item);
            else await this.$emit('copyChange', { value, item, tIndex, prop });
          }
        }
      });
    },
    headerSearchVisibleChange(val, item, index) {
      let temp = this.searchForm[item.otherProp ? item.otherProp : item.prop];
      let temp1 = item.multiple ? temp && temp.length > 0 : temp === 0 ? true : temp;
      if (!val && temp1) {
        this.getData(index);
      }
    },
    headerRemoveTagChange(val, selectedValue) {
      if (selectedValue.length === 0) this.getData();
    },
    columnMinWidth(item) {
      if (item.widthAuto) return 'auto';
      if (item.labelWidth) return item.labelWidth;
      return item.label.length * 14 + 52 + (item.sortable ? 21 : 0) + (item.itemType === 'select' || item.itemType === 'newSelect' ? 10 : 0);
    },
    onLeaveAndClick(event) {
      setTimeout(() => {
        this.$refs.multiTable.clearActived();
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-select__tags-text:has(+ i) {
  width: 40px;
  text-align: left;
}

::v-deep .el-form-item__error {
  display: none;
}
::v-deep .el-popover {
  top: 40px;
  left: -300px;
}

.mgt {
  margin-top: 44px;
}

.tablePagination {
  text-align: right;
  margin-top: 20px;
}

::v-deep .elx-header--column .col--group {
  height: 34px !important;
}

::v-deep .elx-table .elx-cell {
  white-space: normal;
}

::v-deep :root {
  --base-padding-right: 45px; /* 初始基础padding-right值 */
}

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}

::v-deep #dynamicForm .el-input__inner:has(+ .el-input__suffix) {
  padding-right: 50px !important;
}

::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 200px;
}

::v-deep .elx-table.size--medium .elx-header--column.col--ellipsis > .elx-cell {
  max-height: 200px;
}

::v-deep .elx-cell--title {
  width: 100%;
}

::v-deep .el-input .el-input--small .el-input--suffix {
  padding-right: 65px;
}

::v-deep .el-input textarea {
  padding-bottom: 13px;
}

::v-deep .el-input .el-input__count {
  height: 12px;
  line-height: 12px;
}

::v-deep .el-upload {
  display: inline;
  text-align: center;
  cursor: pointer;
  outline: 0;
}

::v-deep .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #e6f7ff;
  border: 0;
}

::v-deep .upload-demo {
  display: inline;
}

::v-deep .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f5f500;
  border: 0;
  width: 80px;
  height: 80px;
}

.summation {
  height: 20px;
  text-align: right;
  padding-right: 10px;
}

.summationBorderBottom {
  border-bottom: 1px solid #494a4d;
}

.daCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
}

.searchPagination {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
</style>
<style>
.dragColbg {
  background-color: #8d8d8d !important;
  color: white !important;
}
/*.sortable-column .elx-header--row .elx-header--column.col--fixed {
  cursor: no-drop !important;
}*/
.my-input-sc {
  display: inline-block;
  line-height: 30px;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  outline: none;
  padding: 0 !important;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px !important;
}
.my-input-sc-disabled {
  background-color: #f3f5f8;
}
.my-input-sc-show {
  background-color: #ffffff;
}

/*.headerRowClass {
  height: 100px !important;
  max-height: 100px !important;
  background-color: #f5f7fa !important;
}
.headerRowClassNotSearch {
  height: 48px !important;
  max-height: 48px !important;
  background-color: #f5f7fa !important;
}*/

.elx-table--tooltip-wrapper.elx-table--valid-error {
  display: none !important;
}

.el-input .el-input__count .el-input__count-inner {
  background: #fff0;
}

.text {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.elx-cell--valid {
  display: none !important;
}

.full-input {
  position: absolute;
  top: 10px;
  left: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1;
}
</style>
